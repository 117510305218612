// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (min-width: 650px) {
  .app-bar {
    grid-template-columns: 1fr auto;
    padding: 8px 32px;
  }

  .app-bar .app-bar__brand h1 {
    font-size: 1.5em;
  }

  .app-bar .app-bar__menu {
    display: none;
  }

  .app-bar .app-bar__navigation {
    position: static;
    width: 100%;
  }

  .app-bar .app-bar__navigation ul li {
    display: inline-block;
  }

  .app-bar .app-bar__navigation ul li a {
    display: inline-block;
    width: 120px;
    text-align: center;
    margin: 0;
  }

  .movies {
    grid-template-columns: 1fr 1fr;

  }

  .movie {
    grid-template-columns: auto 1fr;
  }

  .movie .movie__title {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .movie .movie__overview {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  footer #footerTools {
    flex-direction: row;
  }
}

@media screen and (min-width: 800px) {
  .app-bar .app-bar__brand h1 {
    font-size: 2em;
  }
}

@media screen and (min-width: 850px) {
  .movies {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1200px) {
  .movies {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (min-width: 1600px) {
  .movies {
    grid-template-columns: repeat(5, 1fr);
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/responsive.css"],"names":[],"mappings":"AAAA;EACE;IACE,+BAA+B;IAC/B,iBAAiB;EACnB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,gBAAgB;IAChB,WAAW;EACb;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,qBAAqB;IACrB,YAAY;IACZ,kBAAkB;IAClB,SAAS;EACX;;EAEA;IACE,8BAA8B;;EAEhC;;EAEA;IACE,+BAA+B;EACjC;;EAEA;IACE,oBAAoB;IACpB,kBAAkB;EACpB;;EAEA;IACE,oBAAoB;IACpB,kBAAkB;EACpB;;EAEA;IACE,mBAAmB;EACrB;AACF;;AAEA;EACE;IACE,cAAc;EAChB;AACF;;AAEA;EACE;IACE,qCAAqC;EACvC;AACF;;AAEA;EACE;IACE,qCAAqC;EACvC;AACF;;AAEA;EACE;IACE,qCAAqC;EACvC;AACF","sourcesContent":["@media screen and (min-width: 650px) {\n  .app-bar {\n    grid-template-columns: 1fr auto;\n    padding: 8px 32px;\n  }\n\n  .app-bar .app-bar__brand h1 {\n    font-size: 1.5em;\n  }\n\n  .app-bar .app-bar__menu {\n    display: none;\n  }\n\n  .app-bar .app-bar__navigation {\n    position: static;\n    width: 100%;\n  }\n\n  .app-bar .app-bar__navigation ul li {\n    display: inline-block;\n  }\n\n  .app-bar .app-bar__navigation ul li a {\n    display: inline-block;\n    width: 120px;\n    text-align: center;\n    margin: 0;\n  }\n\n  .movies {\n    grid-template-columns: 1fr 1fr;\n\n  }\n\n  .movie {\n    grid-template-columns: auto 1fr;\n  }\n\n  .movie .movie__title {\n    grid-column-start: 1;\n    grid-column-end: 3;\n  }\n\n  .movie .movie__overview {\n    grid-column-start: 1;\n    grid-column-end: 3;\n  }\n\n  footer #footerTools {\n    flex-direction: row;\n  }\n}\n\n@media screen and (min-width: 800px) {\n  .app-bar .app-bar__brand h1 {\n    font-size: 2em;\n  }\n}\n\n@media screen and (min-width: 850px) {\n  .movies {\n    grid-template-columns: repeat(3, 1fr);\n  }\n}\n\n@media screen and (min-width: 1200px) {\n  .movies {\n    grid-template-columns: repeat(4, 1fr);\n  }\n}\n\n@media screen and (min-width: 1600px) {\n  .movies {\n    grid-template-columns: repeat(5, 1fr);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
